import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"

import headerStyles from "./header.module.scss"

export default function Header() {
  const data = useStaticQuery(graphql`
    query HeaderBg {
      image: file(relativePath: { eq: "barra-superior.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo-central.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [header, setHeader] = useState(true)
  const [navList, setNavList] = useState(true)

  const headerAnimation = () => {
    if (window.scrollY >= 153) {
      setHeader(false)
    } else {
      setHeader(true)
    }
  }

  const showNavList = () => {
    setNavList(!navList)
  }

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", headerAnimation)
  }

  return (
    <header
      className={
        header
          ? headerStyles.header
          : `${headerStyles.header} ${headerStyles.active}`
      }
    >
      <BackgroundImage
        className={headerStyles.background}
        fluid={data.image.childImageSharp.fluid}
      >
        <nav className={headerStyles.navbar}>
          <div
            onKeyDown={() => showNavList("#inicio")}
            role="link"
            tabIndex="0"
            onClick={() => showNavList("#inicio")}
            className={headerStyles.hamburger}
          >
            <div className={headerStyles.line}></div>
            <div className={headerStyles.line}></div>
            <div className={headerStyles.line}></div>
          </div>
          <div
            onKeyDown={() => scrollTo("#inicio")}
            role="link"
            tabIndex="0"
            onClick={() => scrollTo("#inicio")}
            className={headerStyles.brandContainer}
          >
            <Img
              className={headerStyles.brand}
              fluid={data.logo.childImageSharp.fluid}
              alt="Crispy Crab"
            />
          </div>
          <ul
            className={
              navList
                ? headerStyles.navList
                : `${headerStyles.navList} ${headerStyles.active}`
            }
          >
            <li className={headerStyles.navItem}>
              <div
                onKeyDown={() => scrollTo("#posts")}
                role="link"
                tabIndex="0"
                onClick={() => scrollTo("#posts")}
              >
                NOTICIAS Y EVENTOS
              </div>
            </li>
            <li className={headerStyles.navItem}>
              <div
                onKeyDown={() => scrollTo("#nosotros")}
                role="link"
                tabIndex="0"
                onClick={() => scrollTo("#nosotros")}
              >
                ACERCA DE NOSOTROS
              </div>
            </li>
            <li className={headerStyles.navLogoItem}>
              <div
                onKeyDown={() => scrollTo("#inicio")}
                role="link"
                tabIndex="0"
                onClick={() => scrollTo("#inicio")}
                className={headerStyles.headerLogoContainer}
              >
                <Img
                  className={headerStyles.headerLogo}
                  fluid={data.logo.childImageSharp.fluid}
                  alt="CryspyCrab"
                />
              </div>
            </li>
            <li className={headerStyles.navItem}>
              <div
                onKeyDown={() => scrollTo("#menu")}
                role="link"
                tabIndex="0"
                onClick={() => scrollTo("#menu")}
                className={headerStyles.menu}
              >
                MENÚ
              </div>
            </li>
            <li className={headerStyles.navItem}>
              <div
                onKeyDown={() => scrollTo("#contacto")}
                role="link"
                tabIndex="0"
                onClick={() => scrollTo("#contacto")}
              >
                UBICACÍON Y CONTACTO
              </div>
            </li>
          </ul>
        </nav>
      </BackgroundImage>
    </header>
  )
}
