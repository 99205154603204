import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import footerStyles from "./footer.module.scss"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query Footer {
      logo: file(relativePath: { eq: "logo-redondo.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      face: file(relativePath: { eq: "face-footer.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      insta: file(relativePath: { eq: "insta-footer.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.logo}>
        <Img fixed={data.logo.childImageSharp.fixed} />
      </div>
      <div className={footerStyles.info}>
        <p>
          ¡Siguenos en nuestras redes sociales y no te pierdas de nuestros
          eventos, concursos y promociones!
        </p>
        <p>
          Desarrollado por <a href="https://zapy.dev">Zapy.dev</a>
        </p>
      </div>
      <div className={footerStyles.social}>
        <div>
          <a
            href="https://www.facebook.com/Crispy-Crab-103514688151103"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Img fixed={data.face.childImageSharp.fixed} />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/crispycrab_pereira/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Img fixed={data.insta.childImageSharp.fixed} />
          </a>
        </div>
      </div>
    </footer>
  )
}
